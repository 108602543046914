import React from 'react';
import { graphql } from 'gatsby';

// Components
import SeoDetails from '../components/common/seoDetails';
import HeroImage from '../components/common/sections/heroImage';
import FlexBox from '../components/common/sections/flexBox';

const LocationPage = ({ data }) => {
  const { seoContent, contentSections } = data.contentfulPages;

  return (
    <>
      <SeoDetails seoContent={seoContent} />
      <HeroImage seoContent={seoContent} />
      <FlexBox first contentSection={contentSections[0]} />
      <FlexBox reversed contentSection={contentSections[1]} />
      <FlexBox last contentSection={contentSections[2]} />
    </>
  );
};

export const query = graphql`
  query {
    contentfulPages(id: { eq: "aa2b67d9-1d4b-5af7-a5ad-6839170e0e88" }) {
      id
      title
      seoContent {
        pageTitle
        menuTitle
        slug
        description {
          description
        }
        featuredImage {
          title
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      contentSections {
        title
        subtitle
        text {
          childMarkdownRemark {
            html
          }
        }
        image {
          title
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        link {
          pageTitle
          menuTitle
          slug
        }
      }
    }
  }
`;

export default LocationPage;
